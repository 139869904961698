import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHeroLastMileFormData,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import TabPanel, {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
import { PrimaryButton } from '@shipae/components-sandbox/component/buttons';

import SmartLink from 'components/smart-link';
import { useTranslation } from 'src/views/misc/localization';
import { Title, PanelTheme, FormElementTheme } from '../styles';
import { UserInput, InputThemeEx } from './styles';

type LastMileDeliveryProps = {
  id: string,
  title: string,
  PanelContainer?: React.FC<TabPanelPropsType>,
};
const LastMileDelivery: React.FC<LastMileDeliveryProps> = ({
  id,
  title,
  PanelContainer = TabPanel,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    heroLastMileFormData: formData,
  } = useSelector(getHero);
  const {
    firstName, email, emailValidationError,
    createAccountUrl, allowCreateAccount,
  } = formData;

  const handleFirsNameChange = (inpt: string) => dispatch(
    setHeroLastMileFormData({
      ...formData,
      firstName: inpt || '',
    }),
  );
  const handleEmailChange = (inpt: string) => dispatch(
    setHeroLastMileFormData({
      ...formData,
      email: inpt || '',
    }),
  );

  return (
    <PanelContainer id={id} theme={PanelTheme}>
      <Title>{title}</Title>
      <UserInput>
        <FormElement
          label={t('firstName')}
          hasValue={!!firstName}
          theme={FormElementTheme}
          required
        >
          <Input
            onChange={handleFirsNameChange}
            theme={InputThemeEx}
          />
        </FormElement>
        <FormElement
          label={t('email')}
          hasValue={!!email}
          error={t(emailValidationError || '')}
          theme={FormElementTheme}
          required
        >
          <Input
            type="email"
            onChange={handleEmailChange}
            error={!!emailValidationError}
            theme={InputThemeEx}
          />
        </FormElement>
      </UserInput>
      <PrimaryButton
        linkComponent={SmartLink}
        href={createAccountUrl}
        disabled={allowCreateAccount}
      >
        {t('createAccount')}
      </PrimaryButton>
    </PanelContainer>
  );
};

export default LastMileDelivery;
